.navContainer {
  height: 4.3rem;
  display: flex;
  justify-content: flex-end;
  align-content: center;
  align-items: center;
  background-color: black;
  padding: 0.7rem;
  color: white;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
}

.contactUs {
  padding: 0.3rem;
  margin-right: -0.5rem;
}

.mobilePhone {
  margin-left: 1rem;
  margin-right: 0.5rem;
  border: solid 1.2px gray;
  padding: 0.3rem;
  border-radius: 0.2rem;
}

a {
  color: white;
}