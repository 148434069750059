$tablet-breakpoint: 600px;
$laptop-breakpoint: 769px;

.productDescContainer {
  padding: 1rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  }

  .productName {
font-weight: 700;
font-size: 1.5rem;
  }

.imagesContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
  height: 30rem;
  width: 90%;

  @media screen and (min-width: $tablet-breakpoint) {
    height: 40%;
    width: 30%;
}
}

.swiper {
  width: 100%;
  height: 100%;
  border-radius: 0.7rem;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #d7d5d5;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.productImage {
  border-radius: 0.7rem;
}

.price {
  font-weight: 700;
  font-size: 1.3rem;
  width: 100%;
  text-align: center;
}

.descWrapper {
  
}

.description {
  font-size: 1.1rem;
}