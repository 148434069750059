* {
  box-sizing: border-box;
  text-decoration: none;
}

.body {
  min-height: 100vh;
  display: flex;
}

.inputContainer {
  background-color: rgb(193, 223, 244);
  width: 100vw;
  padding: 1rem 1rem 0rem 1rem;
  display: flex;
  justify-content: center;
}

.search {
  height: 1.9rem;
}

.homeContainer {
  background-color: #d7d5d5;
  width: 100vw;
  min-height: 100vh;
  margin-top: 4.3rem;
  padding-right: 1rem;
  display: flex;
  flex-direction: column;
}

.titleWrapper {
  height: 5.5rem;
  width: 100vw;
  font-family: 'Oswald', sans-serif;
  text-align: center;
  background-color: rgb(193, 223, 244);
  margin-bottom: -2rem;
  padding-bottom: 0.3rem;
  display: flex;
  justify-content: center;
  align-items: center;  
  flex-direction: column;
}

.title {
  font-weight:700;
  font-size: 2.5rem;
  margin-bottom: -1.6rem;
  margin-top: 1rem;
}

.contactText {
}

.productsAdvise {
  padding-top: 3rem;
  text-align: center;
  font-family: 'Oswald', sans-serif;
  font-size: 1.2rem;
}

.productsContainer {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  overflow: hidden;
  padding-top: 0.5rem;
}

.productContainer {
  width: 17rem;
  height: 20rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: whitesmoke;
  margin: 0.9rem;
  padding:1rem;
  border-radius: 1rem;
  -webkit-box-shadow: -6px 7px 13px -6px rgba(0,0,0,0.86); 
box-shadow: -6px 7px 13px -6px rgba(0,0,0,0.86);
}

.productContainer:active {
  box-shadow: #D6D6E7 0 3px 7px inset;
  transform: translateY(2px);
}

.newContainer {
  display: flex;
  justify-content: flex-start;
  width: 10%;
  //top: 1rem;
  //margin: -2rem;
  margin-left: -13rem;
  margin-top: -1rem;
  margin-bottom: -0.6rem;
  height: 1.5rem;
}

.minibox {
  position: relative;
  height: 1rem;
  margin-top: 1.2rem;
  margin-right: -4px;
  border: solid 5px black transparent;
}
.new {
  position: relative;
  background: red;
  width: 100px;
  height: 40px;
	display: block;
  height: 1.5rem;
	height: 24px;
	line-height: 24px;
	color: #FFF;
	padding: 0px 10px 0 17px;
  border-right: red 0.5px solid;
  border-bottom-right-radius: 1px;
  border-top-right-radius: 1px;
	z-index:1;
  &:after{
        position: absolute;
        content: "";
        position: absolute;
        margin-left: -1px;
        left: 0;
        bottom: 0;
        width: 0;
        height: 0;
        border-left: 11px solid whitesmoke;
        border-top: 12px solid transparent;
        border-bottom: 12px solid transparent;
        
      }
      
      &:before {
        content: "";
        position: absolute;
        right: -13px;
        bottom: 0;
        width: 0;
        height: 0;
        border-left: 13px solid red;
        border-top: 12px solid transparent;
        border-bottom: 12px solid transparent;
      }
      
}

.isSold {
  background-color: #8d8d8d;
  font-weight: 500;
}

.sold {
  border: solid 1px rgb(180, 5, 5);
  color: rgb(180, 5, 5);
  background-color: rgba(255, 255, 255, 0.345);
  width: 17rem;
  position: absolute;
  text-align: center;
  border-radius: 0.1rem;
}

.imageContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.image {
  height: 13.8rem;
  padding: 1rem;
  margin: 1rem;
  object-fit: cover;
}

 .cardDesc {
   display: flex;
   justify-content: space-evenly;
   width: 17rem;
   padding-left: 0.5rem;
   font-size: 1.1rem;
   color: rgb(53, 53, 53);
 }

.name {
  color: rgb(53, 53, 53);
  font-size: 1.1rem;
}

.homePrice {
  font-weight: 600;
}

.soonTitle {
  background-color: rgb(193, 223, 244);
  font-weight: 500;
  width: 80%;
  color: rgb(53, 53, 53);
  text-align: center;
  border-radius: 0.1rem;
  margin-bottom: -1rem
}

.soonContainer {}

.soonImage {
  margin-bottom: -1.5rem;
}